@import "./custom.scss";

.home_background{
  text-align: center;
  background-image: url("../images/poppymtn2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.home_logo {
  height: 3rem;
  width: auto;
  align-self: flex-start;
}

#btn-large{
  @include button-size(2rem, 3rem, 5rem, 5rem);
}

#btn-small{
  @include button-size(1rem, 4rem, 5rem, 3rem);
}

.btn{
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 94px;
  letter-spacing: 0px;
  text-align: center;
  font-family: 'Georgia, Sans-Serif';
  border-width: 50px;
  border-color: black;
  background: radial-gradient(58.86% 50.14% at 49.92% 50.14%, rgba(21, 35, 21, 0) 42.29%, rgba(1, 37, 0, 0.346164) 76.17%, rgba(1, 31, 0, 0.415) 100%),
  linear-gradient(0deg, #203E2F, #203E2F);
}

.top_row{
  padding-top: 2rem;
}

.middle_row{
  padding-top: 6rem;
  padding-bottom: 3rem;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

nav {
  display: flex;
  margin: 0;
  padding: 0;
  flex-flow: row nowrap;
  width: fit-content;
}

.divline {
    border-top: 1px rounded var(--sealGray);
    margin: 20px 20px;
}