.bubble {
    display: block;
      padding: 10px 20px;
      margin: 15px auto;
      background-color: #203e2f;
      color: white;
      width: 100%;
      text-align: center;
      max-width: 600px;
      border-radius: 30px;
      background: #203e2f;
      box-shadow: 0px 2px 10px rgba(32, 62, 47, 0.3);
    }

.roundbutton{
    display: block;
       padding: 9px 15px;
       margin: 10px auto;
       background-color: gray;
       color: white;
       width: auto;
       text-align: center;
       max-width: 500px;
       border: 1px solid #203e2f;
       border-radius: 20px;
       cursor: pointer;
       transition-duration: 0.4s;

}

.roundbutton:hover {
    background-color: #b6b6b6; /* Green */
    color: white;
  }