/*** animation styles ***/

@keyframes animFade {
    from {opacity: 0;}
    to {opacity: 1;}
}

/*** class styles ***/

.chatHeader {
    display: grid;
    grid-template-columns: repeat(1fr, 2fr, 1fr);
    grid-template-rows: auto;
    grid-template-areas: "left-nav center-nav right-nav";
    align-items: center;
    padding: 20px;
    background-color: white;
    box-shadow: var(--chatBarDist) var(--chatBarDist) var(--chatBarBlur) var(--sealGrayShadow);
    grid-area: "header";
}

.chatHeaderTitle {
    color: var(--polyGreen);
    font-size: 25px;
    grid-area: center-nav;
    margin: auto;
    font-weight: 500;

    animation-name: animFade;
    animation-duration: 1s;
}

.helpIcon {
    color: var(--polyGreen);
    cursor: pointer;
    grid-area: right-nav;
    margin-left: auto;
    margin-right: 0;
}