/*** animation styles ***/

@keyframes animCardStyle {
    from {
        opacity: 0;
        width: 80%;
        transform: translate3d(0px, 50px, 0px);
    }
    to {
        opacity: 1;
        width: 80%;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animTitleStyle {
    from {
        opacity: 0;
        font-size: 30px;
    }
    to {
        opacity: 1;
        font-size: 30px;
    }
}

@keyframes animDescStyle {
    from {
        opacity: 0;
        font-size: 16px;
    }
    to {
        opacity: 1;
        font-size: 16px;
    }
}

.cardStyles {
    justify-content: center;
    align-self: center;
    margin: 0 10%;
    padding: 5%;
    border-radius: 15px;
    background: white;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);

    animation-name: animCardStyle;
    animation-duration: 0.75s;
}

.logoStyle {
    padding: 25px;
    display: block;
    margin: 0 auto;
    width: 50%;
    height: auto;
}

.titleStyle {
    text-align: center;
    font-size: 30px;
    animation-name: animTitleStyle;
    animation-duration: 0.75s;
}

.descriptionStyle {
    font-size: 16px;
    text-align: center;
}