// Custom.scss
$poppy-green : #203e2f; //Consistent Variable to Set Greens
$light-gray : #969696;
$primary:  $poppy-green; //Changing the Primary Color from blue to Poppy Green
$secondary: $light-gray;
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/bootstrap";
/*Custom Code Example:
    https://getbootstrap.com/docs/5.1/components/alerts/
    Alert warning's default background color is yellow. This overwrites the color of the text to white and the background to poppy green. 
    Delete the below .alert-warning code to see the original yellow color
    View the alert-warning HTML in Home.jsx
*/

.alert-warning{
    color: #FFFFFF;
    background-color: tint-color($poppy-green, 40%);
    border-color: $poppy-green;
}


