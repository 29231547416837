  /*** animation styles ***/
@keyframes animCardDonateStyle {
    from {
        opacity: 0;
        transform: translate3d(0em, 3em, 0em);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}


@keyframes animTitleDonateStyle {
    from {
        opacity: 0;
        font-size: 2em;
    }
    to {
        opacity: 1;
        font-size: 2em;
    }
}

.cardDonateStyles {
    justify-content: center;
    align-self: center;
    margin: 1% 0% 0% 0%;
    padding: 3%;
    border-radius: 1em;
    background: 203e2f;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    width: 50%;
    animation-name: animCardDonateStyle;
    animation-duration: 0.75s;
}

.btnStyle {
    /* padding: 25px;
    display: block;
    margin: 0 auto;
    width: 50%;
    height: auto; */
}

.titleDonateStyle {
    text-align: center;
    animation-name: animTitleDonateStyle;
    animation-duration: 1s;
    font-size: 2em;
    /* font-weight: bold; */
    padding-bottom: 20px;
}